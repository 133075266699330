%main-corner {
	background: #ffffff;
	border-radius: 10px;
	position: relative;
	z-index: 10;
}

.students-participation-calendar {
	padding-top: 30px;
	@extend %main-corner;

	.schedule-date-box {
		padding-right: 21px;
		padding-left: 28px;
		display: flex;
		justify-content: space-between;

		.show-month-box {
			width: 229px;
			height: 40px;
			margin-bottom: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: rgba(239, 244, 250, 0.5);
			border-radius: 10px;

			i {
				font-size: 24px;
				color: #3f3d56;
				cursor: pointer;
			}

			.schedule-date {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				color: #3f3d56;
			}
		}

		.right-side {
			width: 574px;
			height: 40px;
			display: flex;
			justify-content: space-between;

			.participation-show-box {
				width: 136px;
				height: 100%;
				display: flex;
				box-sizing: border-box;
				border-radius: 10px;

				.left-box {
					width: 40px;
					height: 100%;
					border-radius: 9px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.right-box {
					width: 96px;
					height: 100%;
					color: red;
					display: flex;
					justify-content: center;
					align-items: center;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 22px;
				}
			}
		}
	}

	.weekly-calendar-left-col-wrapper {
		position: absolute;
		left: -107px;
		top: 137px;
		display: flex;
		flex-direction: row-reverse;
		transform: rotate(-90deg);
		font-size: 12px;
		line-height: 12px;
		height: 30px;

		.weekly-calendar-left-col {
			width: 121px;
			background: #3f3d56;
			color: #ffffff;
			border: 1px solid #d0d0d0;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 9px 40px;
		}
	}

	.weekly-calendar-panel-wrapper {
		padding-left: 30px;
		position: relative;

		.weekly-calendar-day-status {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 44px;
			padding-bottom: 44px;
			margin: 5px;
		}

		.noEntry {
			background-color: #c1c1c1;
		}

		.present {
			background-color: #9ec583;
		}

		.weekly-calendar-lesson-list {
			border: 1px solid #d0d0d0;

			.weekly-calendar-lesson-status {
				padding: 10px;
				margin: 5px;
				color: white;
			}
		}

		.weekly-calendar-panel-top {
			padding: 0;

			.weekly-calendar-panel-boxes {
				border: 1px solid #d0d0d0;
			}

			.weekly-calendar-panel {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #3f3d56;
				color: #ffffff;
				font-size: 12px;
				line-height: 12px;
				height: 30px;
				border: 1px solid #d0d0d0;
			}

			.weekly-calendar-list {
				width: 100%;
				display: flex;

				.day-panel {
					background: #3f3d56;
					display: flex;
					align-items: center;

					p {
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						line-height: 12px;
						color: #ffffff;
						transform: rotate(-90deg);
					}
				}

				.day-panel-head {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 12px;
					padding: 44px 0;
					background: #9ec583;
					color: #ffffff;
				}
			}
		}
	}
}
