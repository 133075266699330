%main-corner {
	background: #ffffff;
	border-radius: 10px;
	position: relative;
	z-index: 10;
}

.annual-subject-plan {
	@extend %main-corner;

	.annual-subject-btn {
		text-align: -webkit-right;
		padding-top: 20px;
		padding-right: 20px;
	}
}

.annual-subject-edit-panel {
	width: 100%;
	height: 760px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 50px;

	.subject-card {
		width: 500px;
		height: 180px;
		display: flex;
		background: #ffffff;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		border-radius: 13px;
		overflow: hidden;
		margin-bottom: 31px;

		.left-side {
			width: 400px;

			.top-box {
				width: 100%;
				display: flex;
				height: 70px;
				padding: 20px 20px 10px;
				border-bottom: 1px solid #ededed;

				.home-person {
					width: 40px;
					height: 40px;
					background: #ededed;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;

					i {
						font-size: 20px;
						color: #7e7e7e;
					}
				}

				.top-title-box {
					margin-left: 10px;

					.main-title {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 17px;

						.title {
							font-style: normal;
							font-weight: 500;
							font-size: 13px;
							line-height: 22px;
							color: #333333;
						}
					}
				}
			}

			.down-box {
				width: 100%;
				height: auto;
				padding: 10px 0 8px 20px;
				box-sizing: border-box;

				.down-box-rows {
					display: flex;
					align-items: center;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}

					.alert-circle {
						margin-right: 5px;
						width: 24px;
						height: 24px;

						i {
							font-size: 24px;
						}
					}

					.alert-title {
						font-style: normal;
						font-weight: 500;
						font-size: 13px;
						line-height: 22px;
					}

					.alert-bold-title {
						span {
							font-style: normal;
							font-weight: 500;
							font-size: 13px;
							line-height: 22px;
						}
					}
				}
			}
		}

		.right-side {
			width: 100px;
			height: 180px;
			border-left: 1px solid #ededed;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			div {
				text-align: center;
				margin-top: 5px;
			}

			.user-group {
				width: 50px;
				height: 50px;
				background: #ededed;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				font-size: 22px;
				color: #7e7e7e;
			}

			.all-students {
				font-style: normal;
				font-weight: 500;
				font-size: 13px;
				line-height: 22px;
			}
		}
	}
}
