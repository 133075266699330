.panner_section {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;

	.block {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 10px;
		background: #ffffff;
		border-radius: 10px;
		width: 14%;
		margin-left: 20px;
		margin-bottom: 50px;

		.title {
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: var(--primary_color);
			width: 100%;
		}

		.date {
			font-weight: normal;
			font-size: 10px;
			line-height: 14px;
			display: flex;
			align-items: center;
			text-align: center;
			justify-content: center;
			color: #c1c1c1;
			margin-bottom: 30px;
			position: relative;
			width: 100%;

			&:before {
				content: ' ';
				position: absolute;
				left: 0;
				top: 30px;
				width: 100%;
				height: 2px;
				background-color: #eff4fa;
			}
		}
	}
}

.block-card {
	width: 100%;
	height: auto;
	background: #eff4fa;
	border-radius: 10px;
	margin-bottom: 20px;
	position: relative;
	overflow: hidden;

	&:hover {
		.start_lesson {
			visibility: visible;
			opacity: 1;

			.text {
				transform: translateY(0);
				transition: all 0.08s ease-in;
			}
		}
	}

	.start_lesson {
		position: absolute;
		visibility: hidden;
		overflow: 0;
		z-index: 2;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.2);
		backdrop-filter: blur(2px);
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		.text {
			display: flex;
			flex-direction: row;
			padding: 5px 10px;
			background: #06bf69;
			border-radius: 10px;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;
			align-items: center;
			color: #ffffff;
			cursor: pointer;
			transform: translateY(100%);
			transition: all 0.08s ease-in;
			user-select: none;
			&:active {
				background: #053f22;
				transform: translateY(2px);
			}
		}
	}

	.header_time {
		width: 100%;
		height: 30px;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		color: #ffffff;
		padding: 6px 10px;
		background: rgba(0, 38, 75, 0.5);
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
		border-radius: 10px;

		&.checked {
			position: relative;
			&:before {
				content: ' ';
				position: absolute;
				right: 0;
				top: 0;
				width: 17px;
				height: 17px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #fff;
			}

			&:after {
				content: ' ';
				position: absolute;
				right: 0;
				top: 1px;
				width: 16px;
				height: 16px;
				background-image: url('../../assets/images/checkedIcon.svg');
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100%;
			}
		}
	}

	.info_subject {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 12px;
		display: flex;
		align-items: center;
		color: #00264b;
		opacity: 0.5;
		padding: 5px 10px;
		margin-top: 5px;
	}

	.info {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;

		.room {
			font-size: 10px;
			line-height: 14px;
			display: flex;
			align-items: center;
			color: #00264b;
			opacity: 0.5;
		}

		.time_lesson {
			font-size: 10px;
			line-height: 14px;
			display: flex;
			align-items: center;
			color: #00264b;
			opacity: 0.5;

			svg {
				padding-right: 3px;
			}
		}
	}
}
