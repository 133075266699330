.login-screen-body {
	display: flex;
	height: 100vh;
	width: 100%;
	alignitems: stretch;
}

.login-page {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	position: relative;
	background: {
		repeat: repeat;
		position-x: center;
		size: cover;
		position-y: bottom;
	}
	.circle-1 {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background: #e7f2dd;
		position: absolute;
		z-index: -200;
		top: -50px;
		left: 50%;
	}
	.circle-2 {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background: #e7f2dd;
		position: absolute;
		z-index: -200;
		top: 20%;
		right: 10%;
	}
	.circle-3 {
		width: 360px;
		height: 360px;
		border-radius: 50%;
		background: #e7f2dd;
		position: absolute;
		z-index: -200;
		bottom: -180px;
		right: -180px;
	}

	.login-wrapper {
		width: 325px;
		margin: 0 auto;

		.brand {
			width: 100%;
			margin-bottom: 25px;
			//margin-top: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		form {
			.form-text {
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
				text-align: center;
				letter-spacing: -0.3px;
				color: #00264b;
				margin-top: 0;
				margin-bottom: 32px;
			}

			.form-group {
				width: 300px;
				margin: 0 auto 32px auto;
				position: relative;

				input[type='text'],
				input[type='password'],
				input[type='email'] {
					height: 45px;
					background: #ffffff;
					border: 1px solid var(--primary_color);
					box-sizing: border-box;
					border-radius: 10px;
					padding: 12px 12px 12px 39px;
					font-size: 14px;
					line-height: 20px;
					color: #00264b;

					&:focus,
					&:hover {
						outline: none;
						box-shadow: none;
					}
				}
				.login-icons {
					position: absolute;
					top: 15.5px;
					left: 15px;
					font-size: 14px;
				}
			}

			.form-bottom {
				width: 300px;
				margin: 0 auto 23px auto;
				display: flex;
				justify-content: space-between;

				.m-checkbox__input {
					position: relative;
					flex-shrink: 0;
					width: 20px;
					height: 20px;
					appearance: none;
					-webkit-appearance: none;
					-moz-appearance: none;
					outline: none;
					cursor: pointer;
					background: #00264b;
					border-radius: 5px;
				}

				.m-checkbox__input::before {
					content: ' ';
					position: absolute;
					top: 2px;
					right: 2px;
					bottom: 2px;
					left: 2px;
					transition: all 0.1s;
					background: var(--primary_color);
					border-radius: 5px;
					opacity: 0;
				}

				.m-checkbox__input:checked::before,
				.m-checkbox__input:indeterminate::before {
					opacity: 1;
				}

				label {
					font-size: 14px;
					line-height: 17px;
					color: #00264b;
					vertical-align: middle;
					display: flex;
					align-items: center;
					margin-bottom: 0;
					&:hover {
						cursor: pointer;
					}
					input[type='checkbox'] {
						margin-right: 12px;
					}
				}

				a {
					font-weight: 500;
					font-size: 14px;
					line-height: 17px;
					text-decoration-line: underline;
					color: #00264b;
				}
			}
			.register {
				width: 300px;
				display: flex;
				justify-content: center;
				margin: 0 auto 23px auto;
				span {
					font-weight: 500;
					font-size: 14px;
					line-height: 17px;
					text-decoration-line: underline;
					color: #00264b;
					cursor: pointer;
				}
			}

			.form-button {
				width: 300px;
				margin: 0 auto;

				.btn {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #00264b;
					box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
				}
			}
		}
	}
}
.oneschool-banner {
	width: 70%;
	height: 100vh;
	background: rgba(136, 189, 86, 1);
	display: flex;
	align-items: center;
	justify-content: center;
	background: {
		repeat: repeat;
		position-x: center;
		position-y: center;
		size: cover;
	}
	img {
		width: 70%;
		//height: 100%;
		//object-fit: cover;
	}
}
