.table thead th {
	border-bottom: unset !important;
}

.table-container {
	width: 100%;
	height: calc(100vh - 180px);
	border: 2px solid #8b9296;
	border-radius: 6px;
	overflow: hidden;

	&_table_wrap {
		.table-bordered {
			border: unset;

			th,
			td {
				border: 2px solid #8b9296;
			}

			thead th {
				border-bottom: unset !important;
			}
		}
		//.table-bordered th, .table-bordered td {
		//  border: 2px solid #8B9296;
		//}
	}

	._text-content-center {
		background-color: #ffffff;
		text-align: center;
		vertical-align: middle !important;
		border-left: unset !important;

		.subject {
			font-weight: 500;
		}
	}

	.order-num {
		border-top: unset !important;
		background-color: #f5f5f5;
		width: 40px;
		position: sticky;
		left: 0;
		z-index: 8;
	}

	.full-name {
		min-width: 230px !important;
		min-height: 280px;
		height: 280px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		border-right: unset !important;
		border-left: unset !important;
		border-top: unset !important;
		position: sticky;
		left: 40px;
		z-index: 8;
		background-color: #f5f5f5;

		.subject {
			font-weight: 500;
		}
	}

	.quarter {
		min-width: 162px;
		width: 162px;
		text-align: center;
		vertical-align: middle !important;
		border-top: unset !important;
		position: sticky;
		left: 269px;
		z-index: 8;
		background-color: #f5f5f5;

		.subject {
			font-weight: 500;
		}
	}

	.subjects {
		vertical-align: middle !important;
		min-width: 60px !important;
		max-width: 60px !important;
		width: 60px !important;
		position: relative;
		border-top: unset !important;

		.subject {
			width: max-content !important;
			position: absolute;
			left: -20px;
			transform: rotate(-90deg);
		}
	}

	.rotated-header-content {
		width: 300px;
		transform-origin: bottom left;
		transform: translateX(30px) translateY(117px) rotate(-90deg);
		font-weight: 500;
	}

	&_table_wrap {
		border-radius: 10px;
		height: 100%;
		overflow-x: auto;
		position: relative;
		background: #ffffff;

		&::-webkit-scrollbar {
			width: 4px;
			height: 6px;
			background: #eff4fa;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #efa06a;
			border-radius: 10px;
			width: 2px;
		}

		.progress-table_head {
			background: #fff;
			//-webkit-box-shadow: 0 1px 10px -3px rgba(173,173,173,1);
			//-moz-box-shadow: 0 1px 10px -3px rgba(173,173,173,1);
			//box-shadow: 0 1px 10px -3px rgba(173,173,173,1);
			position: sticky;
			top: 0;
			z-index: 8;
		}

		.tb-left-0 {
			background-color: #f5f5f5;
			position: sticky;
			left: 0;
			z-index: 7;
		}

		.tb-left-1 {
			position: sticky;
			left: 39px;
			z-index: 7;
			background-color: #f5f5f5;
		}

		.tb-left-2 {
			position: sticky;
			left: 269px;
			z-index: 7;
			background-color: #f5f5f5;
		}
	}
}

.header-row {
	display: flex;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 2;
}

.left-header {
	flex: 0 0 200px;
	z-index: 3;
}

.right-header {
	display: flex;
	flex: 1;
	overflow-x: scroll;
}

.header-cell {
	flex: 1;
	min-width: 100px;
	text-align: center;
	padding: 10px;
	border: 1px solid #ddd;
	background-color: #f9f9f9;
}

.body-container {
	display: flex;
	overflow: hidden;
}

.left-column {
	flex: 0 0 200px;
	overflow-y: scroll;
	position: relative;
	z-index: 1;
	background-color: white;
}

.left-cell {
	padding: 10px;
	background-color: #f1f1f1;
}

.right-body {
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: scroll;
}

.right-row {
	display: flex;
}

.body-cell {
	flex: 1;
	min-width: 100px;
	text-align: center;
	padding: 10px;
	border: 1px solid #ddd;
}

.five {
	background-color: #e2fcb7;
}

.four {
	background-color: #fceab7;
}

.three {
	background-color: #ffa375;
}

.two {
	background-color: #f5b6b3;
}

.two {
	background-color: #f5827d;
}

.RELEASED {
	background-color: #a2e6f4;
}

.NOT_CERTIFIED {
	background-color: #ededed;
}
