.header-wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	div {
		padding: 15px 30px;
		font-size: 16px;
		cursor: pointer;
		border-right: 0.5px solid rgba(0, 0, 0, 0.1);
	}
}

.download-btn {
	border-radius: 10px;
	background: #eff4fa;
	padding: 8px;
	cursor: pointer;
}

.placeholder-wrapper {
	width: 100%;
	height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	img {
		width: 90px;
		height: 100px;
	}

	h3 {
		font-size: 20px;
		font-weight: bold;
		margin: 20px 0px 10px 0px;
	}

	p {
		font-size: 14px;
		color: #c1c1c1;
		width: 245px;
		text-align: center;
	}
}
