@import 'loader';

body {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	color: #00264b;
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	overflow: hidden;

	& > #root {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow: hidden;
	}
}
body.one-theme {
	--primary_color: #77bf44;
}
body.profi-theme {
	--primary_color: #e87525;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
}

p,
span,
div,
a {
	font-size: 14px;
}

.gap-20 {
	gap: 20px;
}

.custom_date_picker {
	.form-group {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		overflow: hidden;

		.calendar-icon {
			position: absolute;
			right: 10px;
			bottom: 10px;
			pointer-events: none;
		}
	}
}

.lesson-topic-select {
	border: 1px solid #eff4fa !important;
	cursor: pointer !important;

	.prefix__control {
		background-color: #ffffff !important;
		cursor: pointer !important;
	}
	.prefix__placeholder {
		color: #00264b !important;
	}
}

.lesson-topic-input {
	height: 42px;
	border-radius: 5px;

	&::placeholder {
		font-size: 14px;
	}
}

.lesson-topic-textarea {
	width: 100%;
	height: 60px;
	background: #ffffff;
	border-radius: 10px;
	font-size: 14px;
	line-height: 22px;
	padding: 8px 12px;
	border: 1px solid #eff4fa;
	resize: none;

	&:focus {
		outline: none;
		box-shadow: none;
		border-color: #ced4da;
	}
}

.lesson-topic-label {
	font-weight: 500;
	color: #00264b;
	line-height: 22px;
	margin-bottom: 5px;
	text-align: center;
}

.lesson-topic-form {
	.form-group {
		margin-bottom: 0 !important;
	}
}

.lesson-topic-add-btn {
	width: 100%;
	height: 30px;
	background-color: #0d6efd;
	border: none;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	color: #ffffff;
}

.lesson-topic-btn {
	background-color: #979797;
	padding: 12px 0 !important;
}

.divider {
	border-bottom: 1px solid #eff2f8;
}

.right-icon {
	display: flex;
	flex-direction: row-reverse;
	gap: 10px;
}

%main-corner {
	background: #ffffff;
	border-radius: 10px;
	position: relative;
	z-index: 10;
}

%main-date-picker-wrapper {
	position: relative;

	.icon-bxs-calendar {
		position: absolute;
		top: 8px;
		right: 11px;
		font-size: 22px;
		pointer-events: none;
		cursor: pointer;
	}

	.modal-date-picker {
		width: 180px;
		height: 42px;
		background-color: #fafafa;
		padding-left: 15px;
		border: 1px solid #ffffff;
		box-sizing: border-box;
		border-radius: 10px;

		&:focus-visible {
			outline: none;
			box-shadow: 0 0 0 0.2rem rgba(191, 222, 255, 25%);
		}
	}
}

.main-form-control {
	display: block;
	height: 40px;
	background-color: #fafafa;
	border: 1px solid #ffffff;
	border-radius: 10px;

	&:focus {
		outline: none;
		box-shadow: none;
		border-color: #2684ff;
	}
}

.btn-default {
	height: 40px;
	outline: none;
	background-color: var(--primary_color);
	color: #ffffff;
	padding: 9px 15px;
	border-radius: 10px;
	display: flex;
	align-items: center;

	&:hover,
	&:active,
	&:focus {
		background-color: #e89c25;
		color: #ffffff;
		outline: none;
		box-shadow: none;
	}
}

.btn-success,
.btn-danger {
	height: 40px;
	outline: none;
	background-color: var(--primary_color);
	color: #ffffff;
	padding: 9px 15px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	border: none;

	&:hover,
	&:active,
	&:focus {
		background-color: #099554;
		color: #ffffff;
		outline: none;
		border: none;
	}
}

.btn-danger {
	background: #ff4747 !important;
	border-radius: 10px !important;

	&:hover,
	&:active,
	&:focus {
		background-color: #e30000 !important;
	}
}

.btn-light-default {
	height: 40px;
	outline: none;
	padding: 12px;
	background-color: #ffffff;
	color: #c1c1c1;
	font-size: 16px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
	border-radius: 10px;
	border: none;

	i {
		vertical-align: top;
	}

	&:hover,
	&:active,
	&:focus {
		color: #ffffff;
		background-color: #c1c1c1;
		outline: none;
		box-shadow: none;
	}
}

.btn-default.btn-sm {
	padding: 5px 10px;
	border-radius: 10px;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mr-20 {
	margin-right: 20px !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.mt-30 {
	margin-top: 30px !important;
}

.pl-10 {
	padding-left: 9.5px !important;
}

.pr-13 {
	padding-right: 13px !important;
}

.disabled-btn {
	opacity: 0.5;
	pointer-events: none;
}

.--sorted-active {
	display: inline-block;
	transition: all 0.2s ease-in-out;
	transform: rotate(180deg);
	color: #e87525;
}

.--dropdown-active {
	transition: all 0.3s ease-in-out;
	transform: rotate(-180deg);
}

.--dropdown-inactive {
	transition: all 0.3s ease-in-out;
	transform: rotate(0deg);
}
.--rollback {
	background-color: #eee !important;
	border-top: 1px solid white;
}

.rollback-btn {
	width: 20px;
	height: 20px;
	border: 2px solid #c1c1c1;
	border-radius: 50%;
	margin-left: 25px;
}

.--rollback-student-item {
	cursor: not-allowed;
	display: flex;
	gap: 10px;
	padding: 8px 16px;
	min-width: 5.625rem;
	min-height: 2.5rem;
	align-items: center;
	user-select: none;
	border-radius: 0.375rem;
	justify-content: center;
	background-color: #06bf69;
	opacity: 0.5;
	color: #fff;
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 1.125rem;
}

.archive-table-checkbox {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 25px;

	.checkbox_input {
		position: relative;
		flex-shrink: 0;
		width: 20px;
		height: 20px;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		outline: none;
		cursor: pointer;
		border: 2px solid #c1c1c1;
		border-radius: 50%;
	}

	.checkbox_input::before {
		content: 'L';
		position: absolute;
		top: -4px;
		left: 3px;
		color: #06bf69;
		font-size: 12px;
		font-weight: 700;
		transition: all 0.1s ease-in-out;
		border-radius: 5px;
		opacity: 0;
		font-family: arial;
		-ms-transform: scaleX(-1) rotate(-35deg);
		-webkit-transform: scaleX(-1) rotate(-35deg);
		transform: scaleX(-1) rotate(-35deg);
	}

	.checkbox_input:checked {
		border: 2px solid #06bf69;
	}

	.checkbox_input:checked::before,
	.checkbox_input:indeterminate::before {
		opacity: 0;
	}
}

.class-tab-loader {
	margin: 0 !important;
	font-size: 25px;
}

#input-radio input[type='checkbox']::before {
	content: '';
	position: absolute;
	display: inline-block;
	width: 12px;
	height: 12px;
	top: 2px;
	left: 2px;
	background-color: #06bf69;
	transition: all 0.1s ease-in-out;
	border-radius: 50%;
	opacity: 0;
}

#input-radio input[type='checkbox'] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	position: relative;
	width: 20px;
	height: 20px;
	background-color: #fff;
	border: 2px solid #c1c1c1;
	border-radius: 50%;
	transition: border-color 0.3s;
	cursor: pointer;
}

#input-radio input[type='checkbox']:checked {
	border-color: #06bf69;
}

#input-radio input[type='checkbox']:checked::before {
	opacity: 1;
}

#app-content::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
	background-color: #cccccc;
}

#app-content::-webkit-scrollbar {
	width: 10px;
	background-color: #f5f5f5;
}

#app-content::-webkit-scrollbar-thumb {
	background-color: #fff;
	background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 25%, transparent 100%, rgba(0, 0, 0, 1) 75%, transparent);
}

.react-datepicker-wrapper {
	width: 100% !important;
}

.--isEdit {
	cursor: not-allowed;
}

@font-face {
	font-family: 'Rubik';
	src: url('../fonts/Rubik-Bold.eot');
	src: url('../fonts/Rubik-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Rubik-Bold.woff2') format('woff2'),
		url('../fonts/Rubik-Bold.woff') format('woff'), url('../fonts/Rubik-Bold.ttf') format('truetype'),
		url('../fonts/Rubik-Bold.svg#Rubik-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Rubik';
	src: url('../fonts/Rubik-Italic.eot');
	src: url('../fonts/Rubik-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/Rubik-Italic.woff2') format('woff2'),
		url('../fonts/Rubik-Italic.woff') format('woff'), url('../fonts/Rubik-Italic.ttf') format('truetype'),
		url('../fonts/Rubik-Italic.svg#Rubik-Italic') format('svg');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Rubik';
	src: url('../fonts/Rubik-Medium.eot');
	src: url('../fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/Rubik-Medium.woff2') format('woff2'),
		url('../fonts/Rubik-Medium.woff') format('woff'), url('../fonts/Rubik-Medium.ttf') format('truetype'),
		url('../fonts/Rubik-Medium.svg#Rubik-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Rubik';
	src: url('../fonts/Rubik-Regular.eot');
	src: url('../fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Rubik-Regular.woff2') format('woff2'),
		url('../fonts/Rubik-Regular.woff') format('woff'), url('../fonts/Rubik-Regular.ttf') format('truetype'),
		url('../fonts/Rubik-Regular.svg#Rubik-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

.oh-no {
	width: 100vw;
	height: 100vh;
	//background: #F3F3F3;
	pointer-events: none;
	z-index: 20;
}

.popover {
	border-radius: 11px;
	width: 260px;
	z-index: 31;

	.arrow {
		display: none;
	}

	.popover-header {
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
		background-color: #ff4747;
		color: #ffffff;

		.title {
			display: flex;
			justify-content: center;
			align-items: center;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 18px;

			i {
				font-size: 24px;
				margin-right: 10px;
			}
		}
	}

	.popover-header.popover-activation-header {
		background-color: var(--primary_color);
	}

	.popover-body {
		.btn-sm {
			height: auto;
			padding: 5px;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 18px;

			&:nth-child(2) {
				background: #ffffff;
				color: #00264b;
			}
		}
	}
}

.custom-popup {
	z-index: 9999;
	max-width: 285px !important;
	width: 100% !important;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	border: unset !important;
	// height: 374px;

	.popover-header {
		background-color: #e87525;

		.title {
			justify-content: flex-start;
		}
	}

	.popover-body {
		padding: 10px 7px 0 10px;

		.info-popover-content {
			// max-height: 300px;
			// height: 100%;
			// overflow-y: auto;
			position: relative;
			display: flex;
			flex-direction: column;
			gap: 21px;

			&::-webkit-scrollbar {
				width: 3px;
				border-radius: 10px;
				background-color: #eff4fa;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #fff;
				border-radius: 10px;
				background: #ff9060;
				box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
			}

			.hr_line {
				position: absolute;
				width: 94%;
				height: 100%;
				left: 0;
				bottom: -10px;
				height: 1px;
				background: #ebeff5;
			}
		}
	}
}

.delete-popup {
	.popover-header {
		background-color: #ff4747;
	}

	.popover-body {
		.info-popover-content {
			&::-webkit-scrollbar-thumb {
				background-color: #fff;
				border-radius: 10px;
				background: #ff4747;
				box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
			}
		}
	}
}

.refresh-popup {
	.popover-header {
		background-color: #06bf69;
	}

	.popover-body {
		.info-popover-content {
			&::-webkit-scrollbar-thumb {
				background-color: #fff;
				border-radius: 10px;
				background: #06bf69;
				box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
			}
		}
	}
}

.custom-switch .custom-control-label::before {
	cursor: pointer;
	border-radius: 22px;
	width: 44px;
	height: 22px;
	top: 0;
}

.custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: none;
}

.custom-control-label::before {
	border: none;
}

.custom-switch .custom-control-label::after {
	cursor: pointer;
	background-color: #c1c1c1;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	top: 0;
	left: calc(-2.25rem);
}

.custom-switch .custom-control-input:checked ~ .custom-control-label {
	&:before {
		background-color: #ffffff;
	}

	&:after {
		background-color: #9ec583;
		transform: translateX(22px);
	}
}

.app-content {
	width: 100%;
	min-height: 100%;
	background: #eff4fa;
	padding: 30px;
	overflow: auto;
}

.app-left-side {
	min-width: 235px;
	height: 100vh;
	overflow: hidden;
	padding-top: 15px;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
	z-index: 10;

	.wrap-brand {
		padding-left: 45px;
		margin-bottom: 37px;

		.profi-brand {
			position: relative;
			width: 145px;
			height: fit-content;
			min-height: 81.5px;
			display: flex;
			align-items: center;

			img {
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: contain;
				top: 0;
				left: 0;
			}
		}
	}
}

.app-right-side {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	z-index: 5;
}

.archive-list-header {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 20px;
}

@media (min-width: 1400px) {
	.col-xxl-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
}

@media (max-width: 1400px) {
	.chat-list {
		.row {
			.col-md-4 {
				.left-chat-header {
					.left-chat-create-chat-btn {
						left: 310px;
					}
				}
			}

			.col-md-8 {
				.right-chat-header {
					.row {
						.col {
							.right-chat-list {
								.user-typing-list {
									right: 25px;
									width: 53.6%;
								}
							}
						}
					}
				}
			}
		}
	}

	.app-left-side {
		min-width: 200px;

		.wrap-brand {
			padding-left: 25px;
		}

		.scroll_side_bar {
			.menu_side {
				margin-bottom: 60px;

				.menu-list {
					.menu-item {
						padding: 12px 15px;

						.menu-item-text {
							margin-left: 11px;
							font-size: 13px;
						}
					}
				}
			}
		}
	}

	.custom-card.without-header {
		.card-body {
			padding: 15px !important;
		}
	}

	p,
	span,
	div,
	a {
		font-size: 13px;
	}
}

.app-header {
	z-index: 10;
	width: 100%;
	padding: 15px 30px;

	.dropdown {
		.dropdown-toggle {
			width: 100%;
			height: 40px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 15px;
			background: #f3f3f3;
			color: #000000;
			border: none;
			border-radius: 10px;

			&:focus,
			&:active {
				background-color: #f3f3f3;
				color: #000000;
				border: none;
				box-shadow: none !important;
			}

			.icon-university {
				font-size: 24px;
			}

			div {
				display: flex;
				align-items: center;
			}

			.university-title {
				margin-left: 15px;
				color: #000000;
			}

			&:after {
				display: none;
			}
		}

		span {
			font-size: 14px;
			line-height: 22px;
		}
	}

	.input-group {
		height: 40px;
		flex-wrap: nowrap;
		display: flex;
		align-items: center;
		padding: 0;

		.input-group-text {
			height: 40px;
			border: {
				top-right-radius: 0;
				bottom-right-radius: 0;
				top-left-radius: 10px;
				bottom-left-radius: 10px;
			}
			background: #f3f3f3;
			padding: 10px 15px;
			border: none;

			.header-item-icon {
				font-size: 24px;
			}
		}

		.form-control {
			height: 40px;
			background: #f3f3f3;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
			font-size: 14px;
			line-height: 22px;
			border: none;

			&:focus {
				outline: none;
				box-shadow: none;
				border-color: #ced4da;
			}
		}
	}

	.header-notifications {
		display: flex;
		align-items: center;

		.box-notifications {
			margin-right: 32px;

			div {
				cursor: pointer;
				width: 24px;
				height: 24px;
				text-align: center;
				align-items: center;
				font-size: 20px;

				i {
					color: #c1c1c1;
				}
			}
		}
	}

	.btn-default {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 10px;

		.icon-plus {
			font-size: 21px;
		}
	}
}

.textarea-input {
	height: 100px;
	background: #f8f8f8;
	border-radius: 10px;
	font-size: 14px;
	line-height: 22px;
	padding: 8px 12px;
	border: none;
	resize: none;

	&:focus {
		outline: none;
		box-shadow: none;
		border-color: #ced4da;
	}
}

.menu_side {
	margin-bottom: 120px;
}

.scroll_side_bar {
	// border-radius: 10px;

	& > div:nth-child(2) {
		height: 2px !important;
		cursor: pointer;
		z-index: 2;
	}

	& > div:nth-child(3) {
		width: 2px !important;
		cursor: pointer;
		z-index: 2;
	}
}

.menu-list {
	list-style: none;

	.dropdown-menu {
		position: unset !important;
		transform: unset !important;
		border: none;
		width: calc(100% - 25px);
		margin-left: 25px !important;
		padding: 0 !important;
	}
}

.custom-tab {
	.custom-tab-buttons-wrap {
		display: flex;
		min-height: 45px;
		//width:300px;
		position: relative;
		top: 8px;
		z-index: 0;

		.custom-tab-buttons {
			background: #ffffff;
			margin-right: 10px;
			padding: 9px 20px 9px;
			color: #c1c1c1;
			border-top-right-radius: 10px;
			border-top-left-radius: 10px;
			cursor: pointer;
			transition: all 0.3s ease;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			//line-height: 22px;

			&:hover {
				color: var(--primary_color);
			}
		}
	}

	.custom-tab-buttons.active {
		color: var(--primary_color);
	}

	.custom-tab-content {
		.custom-tab {
			padding-top: 10px;

			.custom-tab-buttons-wrap {
				display: flex;
				height: 45px;
				position: relative;
				top: 8px;

				.custom-tab-buttons {
					background: #fafafa;
				}
			}
		}
	}
}

.menu-item.active {
	background: #eff4fa;
	color: var(--primary_color);
	border-right: 2px solid var(--primary_color);

	// path {
	// 	fill: var(--primary_color);
	// }
}

.menu-item {
	padding: 15px 26px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
	transition: all 0.3s ease;
	border-right: 2px solid #ffffff;

	svg {
		width: 24px !important;
		height: 24px !important;
	}

	&:hover {
		background: #fafafa;
		color: var(--primary_color);
		border-right: 2px solid var(--primary_color);

		//svg {
		//	fill: var(--primary_color);
		//}
	}

	.menu-item-icon {
		width: 24px;
		height: 24px;
		font-size: 24px;
	}

	.menu-item-text {
		margin-left: 20px;
		font-size: 14px;
	}
}

.custom-card.without-header {
	.card-body {
		padding: 20px;
	}
}

.text-gray {
	color: #c1c1c1;
}

.text-sm {
	font-size: 12px;
}

.text-green {
	color: #06bf69;
}

.text-red {
	color: #ff4949;
}

.custom-card {
	margin-bottom: 30px;
	border: none;
	background: #ffffff;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
	border-radius: 10px;

	.card-title {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
		color: #000000;
		padding: 10px 20px 0;

		.card-title-text {
			padding-bottom: 10px;
		}
	}

	.card-body {
		padding: 0 20px 10px;

		.others {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;
			color: #3f3d56;
			margin: 10px 0;
		}

		.text {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			.icon-briefcase-medical {
				font-size: 20px;
				margin-bottom: 2px;
				color: rgba(63, 61, 86, 1);
			}

			.address {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 22px;
				color: #c1c1c1;
				flex: none;
				order: 0;
				flex-grow: 0;
			}

			.address-text {
				display: flex;
				align-items: center;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 22px;
				color: #333333;
				flex: none;
				order: 1;
				flex-grow: 0;
				margin-left: 10px;

				.icon-map-marker-line {
					margin-left: 54px;
					font-size: 14px;
					height: 20px;
					color: #c1c1c1;
				}
			}
		}
	}
}

.modal {
	.modal-dialog:not(.modal-sm) {
		max-width: 550px;

		.modal-content {
			padding: 20px;

			.modal-header {
				border: none;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 28px;
				color: #333333;
				display: flex;
				align-items: center;
				padding: 0;
				margin-bottom: 10px;
			}

			.modal-body {
				padding: 0;
				margin-bottom: 20px;

				.form-label {
					font-style: normal;
					font-weight: normal;
					font-size: 10px;
					line-height: 14px;
					color: #c1c1c1;
					vertical-align: bottom;
					margin: 0;
				}

				.form-group {
					input {
						height: 40px;
						background: #fafafa;
						border-radius: 10px;
						border: none;
						outline: none;
						padding: 10px 15px;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 22px;
						color: #333333;
					}
				}
			}

			.modal-footer {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				border: none;
				padding: 0;
				margin: 0;
			}

			.modal-footer > * {
				margin: 0;
			}
		}
	}
}

.searchable-select-input-changes {
	.css-1uccc91-singleValue {
		margin-top: -5px;
	}
	input {
		margin-top: -5px;
	}
	.css-tlfecz-indicatorContainer {
		margin-top: -5px;
	}
	.css-1okebmr-indicatorSeparator {
		margin-bottom: 12px;
	}
	.css-1gtu0rj-indicatorContainer {
		margin-top: -5px;
	}
}

.custom-form-control {
	border-color: var(--primary_color);
	border: 1px solid rgba(0, 0, 0, 0);
	border-radius: 10px;
	margin-bottom: 10px;
	height: 43px;

	&:hover,
	&:focus,
	&:active {
		border: 1px solid var(--primary_color);
	}

	.css-1pahdxg-control {
		box-shadow: none;

		&:hover {
			border: none;
		}
	}

	.css-yk16xz-control,
	.css-1pahdxg-control {
		height: 40px;
		background: #fafafa;
		border-radius: 10px;
		border: none;
		outline: none;
		padding: 0 15px;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
		color: #333333;

		.css-1hb7zxy-IndicatorsContainer {
			span {
				display: none;
			}
		}

		.css-g1d714-ValueContainer {
			padding: 0;
		}
	}
}

.role-edit {
	.card {
		margin-bottom: 20px;
		border-radius: 10px;
		border: none;

		&:first-child {
			margin-bottom: 30px;
		}

		.card-header {
			background-color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 20px;
			border-bottom: none;
			border-radius: 10px;

			.input-group {
				.form-control {
					max-width: 349px;
					height: 40px;
					background: #f3f3f3;
					border-radius: 10px;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 22px;
					color: #333333;
					padding: 10px 15px;

					&:focus {
						outline: none;
						box-shadow: none;
						border-color: #ced4da;
					}
				}
			}
		}

		.card-body {
			padding: 20px;

			.card-title {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				color: #000000;
				margin-bottom: 0;
			}

			.add-payment-list {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 20px;

				&:first-child {
					margin-top: 0;
				}

				.add-payment {
					display: flex;
					align-items: center;
					justify-content: space-between;
					border-radius: 10px;
					padding: 10px 15px;
					background: #fafafa;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 22px;
					color: #333333;

					form {
						width: 44px;
						height: 22px;
						margin-left: 23px;

						.custom-switch {
							display: flex;
							align-items: flex-start;
							padding-top: 11px;
							padding-left: 2.25rem;
							width: 100%;
						}
					}
				}
			}
		}
	}

	.modal-wrapper {
		.modal-input-group {
			display: flex;
		}
	}
}

.students-avatar {
	display: flex;
	align-items: center;

	div {
		width: 45px;
		height: 45px;
		margin-right: 20px;
		position: relative;

		.user-online {
			position: absolute;
			padding: 3px 4px 4px 3px;
			background-color: #06bf69;
			border-radius: 50%;
			bottom: 0;
			right: 0;
			border: 2.5px solid #ffffff;
		}
	}
}

.students-payments {
	background: #ffffff;
	border-top-right-radius: 10px;

	.students-payment-wrapper {
		padding-right: 20px;
		background: #ffffff;
		border-top-right-radius: 10px;
		height: 87px;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.students-payment-position {
			margin-right: 20px;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;

			&:nth-child(3) {
				margin-right: 50px;
			}

			.total-payment-status {
				color: #3f3d56;
			}

			.paid-payment-status {
				color: #25c77b;
			}

			.unpaid-payment-status {
				color: #ff4949;
			}
		}
	}
}

.students-payments-modal {
	.modal-input-group {
		display: flex;
		justify-content: space-between;

		.modal-select {
			width: 300px;

			.custom-form-control {
				border: 1px solid #eff4fa;
				box-sizing: border-box;
				border-radius: 10px;
			}
		}

		.modal-date-picker-wrapper {
			@extend %main-date-picker-wrapper;
		}
	}

	.user-box {
		width: 100%;
		height: 50px;
		margin-top: 20px;
		display: flex;
		align-items: center;
		padding-left: 15px;
		border: 1px solid #eff4fa;
		border-radius: 10px;
		background: #fafafa;

		.user-name {
			margin: 14px 0 14px 15px;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;
			color: #00264b;
		}
	}

	.modal-checkbox {
		margin-top: 13px;
		display: flex;
		align-items: center;

		.parents-notice {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;
			color: #00264b;
			margin-left: 10px;
		}

		.m-checkbox__input {
			position: relative;
			flex-shrink: 0;
			width: 16px;
			height: 16px;
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			outline: none;
			cursor: pointer;
			background-color: initial;
			border: 1px solid #a3a3a3;
			border-radius: 3px;
		}

		.m-checkbox__input::before {
			content: ' ';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transition: all 0.1s;
			background: var(--primary_color);
			border-radius: 1px;
			opacity: 0;
		}

		.m-checkbox__input:checked::before,
		.m-checkbox__input:indeterminate::before {
			opacity: 1;
		}
	}

	.additional-field-box {
		margin-top: 18px;

		.additional-field {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;
			color: #00264b;
			margin-bottom: 5px;
		}
	}
}

.modal-buttons {
	display: flex;
	justify-content: flex-end;

	.btn-default {
		margin-right: 20px;

		&:last-child {
			margin: 0;
		}
	}
}

.students-characteristic-assessment {
	.col-title {
		margin-top: 30px;
		margin-bottom: 8px;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		color: #3f3d56;
	}

	.card {
		.card-body {
			.btn-sm {
				padding: 10px 15px;
			}

			.top-title {
				display: flex;
				justify-content: space-between;

				p {
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: #3f3d56;
				}
			}

			.top-percent {
				display: flex;
				margin-bottom: 15px;

				p {
					font-weight: 500;
					font-size: 36px;
					line-height: 48px;
					color: #3f3d56;
				}

				.top-rating {
					display: flex;
					align-items: flex-start;

					.text-gray {
						margin-right: 15px;
					}

					.rating {
						background: linear-gradient(0deg, rgba(255, 73, 73, 0.1), rgba(255, 73, 73, 0.1)), #ffffff;
						border-radius: 6px;
						display: flex;
						align-items: center;
						padding: 8px 6px;
						box-sizing: border-box;
						height: 24px;
						font-weight: 500;
						font-size: 13px;
						line-height: 20px;
						color: #ff4949;
						margin-right: 16px;

						.icon-arrow-down {
							font-size: 10px;
							color: #ff4949;
							margin-right: 6px;
						}
					}

					.sticker {
						.icon-bx-smile {
							color: #ff4949;
							font-size: 18px;
						}
					}
				}
			}

			.indicators {
				.indicators-item {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 30px;

					p {
						font-weight: 500;
						font-size: 13px;
						line-height: 20px;
						color: #3f3d56;
					}

					span {
						font-size: 13px;
						line-height: 20px;
						color: #3f3d56;
					}

					div {
						span {
							.icon-arrow-down {
								margin: 0 8px 0 34px;
								font-size: 11px;
								color: #ff4949;
							}
						}
					}
				}
			}

			.form-group {
				margin-bottom: 0;

				label {
					margin-bottom: 0;
				}

				.form-control {
					height: 146px;
					border-color: #eff4fa;
					border-radius: 0;
				}

				div {
					display: flex;
					justify-content: flex-end;
					margin-top: 20px;
				}
			}
		}
	}

	.evaluation-card {
		padding: 10px 25px 12px 10px !important;
		margin-bottom: 20px;

		.evaluation {
			display: flex;

			.commit {
				margin-left: 15px;
			}
		}
	}

	.commit-card {
		padding: 12px 10px 42px;

		.date {
			text-align: center;
			font-weight: normal;
			font-size: 13px;
			line-height: 22px;
			color: #3f3d56;
			margin-bottom: 22px;
		}

		.commit-owner {
			display: flex;
			align-items: center;

			img {
				width: 45px;
				margin-right: 10px;
			}

			.owner {
				font-size: 14px;
				line-height: 22px;
				color: #3f3d56;
			}
		}

		.commit-list {
			width: 75%;
			padding: 5px 10px;
			font-size: 14px;
			line-height: 22px;
			color: #3f3d56;
			background: rgba(239, 244, 250, 0.5);
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
			border-radius: 0 10px 10px 10px;
			margin-left: auto;
			margin-bottom: 22px;

			.time {
				font-weight: normal;
				font-size: 10px;
				line-height: 14px;
				color: #c1c1c1;
				text-align: end;
				margin-top: 10px;
			}
		}
	}
}

.students-table-payment {
	display: flex;
	align-items: center;
	justify-content: space-between;

	span {
		font-size: 22px;
		color: #3f3d56;
		cursor: pointer;
	}
}

.students-table-status {
	display: flex;
	justify-content: center;
	align-items: center;

	span {
		margin-right: 10px;
		font-size: 20px;
		color: #06bf69;
	}
}

.teachers-plan {
	padding: 50px 50px 183px;
	margin-bottom: 0;

	.card-body {
		.form {
			margin-bottom: 43px;

			.custom-form-control {
				.css-yk16xz-control {
					border: none;

					&:hover,
					&:active,
					&:focus {
						border-color: transparent !important;
					}

					.css-1hb7zxy-IndicatorsContainer {
						span {
							display: none;
						}
					}
				}

				.css-1pahdxg-control {
					border: none;
					outline: none;
					box-shadow: none;

					.css-1hb7zxy-IndicatorsContainer {
						span {
							display: none;
						}
					}
				}
			}
		}

		.custom-card {
			margin-bottom: 0;
		}

		.cards {
			margin-bottom: 80px;

			.col {
				display: flex;
				justify-content: center;

				.card-plan {
					width: 200px;
					height: 100px;
					padding: 20px 15px;
					position: relative;
				}

				.card-plan-first {
					background-image: url('../../assets/images/card-red.svg');
				}

				.card-plan-second {
					background-image: url('../../assets/images/card-orange.svg');
				}

				.card-plan-third {
					background-image: url('../../assets/images/card-blue.svg');
				}

				.card-class-name {
					position: absolute;

					.card-class-position {
						font-weight: 500;
						font-size: 25px;
						line-height: 30px;
						letter-spacing: 0.5px;
						color: #ffffff;
					}

					.card-class-name-title {
						font-weight: 500;
						font-size: 15px;
						line-height: 18px;
						letter-spacing: 0.5px;
						color: #ffffff;
					}
				}
			}
		}
	}
}

.lessons-math-list {
	.lessons-math-list-btn {
		display: flex;
		justify-content: flex-end;
		margin: 20px;
	}
}

.teachers-time-table-wrapper {
	padding: 0 100px;

	.time-table {
		margin: 0;
		border-bottom: 1px solid #3f3d56;
		border-left: 1px solid #3f3d56;
		border-top: 1px solid #3f3d56;
		border-top-left-radius: 10px;

		.table-column {
			padding: 0;
			border-right: 1px solid #3f3d56;

			.table-time-column {
				width: 100%;
				height: 50px;
				font-size: 21px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-bottom: 1px solid #3f3d56;
			}

			.time-columns {
				width: 100%;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-bottom: 1px solid #3f3d56;

				&:last-child {
					border-bottom: none;
				}
			}

			.column-head {
				width: 100%;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #3f3d56;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
				color: #ffffff;
			}

			.time-table-lessons-list-group {
				.table-lessons-box {
					width: 100%;
					height: 50px;
					border-bottom: 1px solid #3f3d56;

					.lessons-science {
						padding-left: 10px;
						padding-top: 10px;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 12px;
						color: #3f3d56;
					}

					.lessons-science-box {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 0 10px;

						.lessons-class {
							font-style: normal;
							font-weight: normal;
							font-size: 12px;
							line-height: 12px;
							color: #3f3d56;
						}

						.lessons-room {
							display: flex;
							align-items: center;
							font-style: normal;
							font-weight: normal;
							font-size: 12px;
							line-height: 22px;

							i {
								margin-right: 8px;
								color: #3f3d56;
								font-size: 20px;
							}
						}
					}
				}
			}
		}
	}
}

.chat-list {
	// margin: -30px -30px;
	overflow: hidden;
	display: flex;

	.chat-left-side-padding {
		padding: 15px 20px;
	}

	.chat-left-side {
		height: 100vh;

		width: 28%;
	}

	.chat-right-side {
		height: 100vh;
		width: 72%;
	}

	.group-wrapper {
		display: flex;
		align-items: center;
		gap: 15px;
		margin: 0px 14px;
		overflow-x: auto;
		padding-bottom: 5px;
		height: 115px;
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			border-radius: 2px;
			background-color: #f5f5f5;
		}
		&::-webkit-scrollbar {
			height: 3px;
			background-color: #f5f5f5;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 2px;
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			background-color: #e0601d;
		}
	}
	.group-item {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		flex-direction: column;
		padding: 10px 12px;
		cursor: pointer;
		//:hover {
		//  background: #EBF1F8;
		//}
	}
	.group-icon {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 5px;
	}

	.chart-message-list {
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			border-radius: 2px;
			background-color: #f5f5f5;
		}

		&::-webkit-scrollbar {
			width: 3px;
			background-color: #f5f5f5;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 2px;
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			background-color: #555;
		}
	}

	.left-chat-header {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100vh;
		background-color: #ffffff;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
		overflow: hidden;

		.input-group {
			display: flex;
			flex-direction: row-reverse;
			height: 40px;
			flex-wrap: nowrap;
			align-items: center;
			padding: 0;

			.input-group-text {
				height: 40px;
				background: #f3f3f3;
				border: {
					top-right-radius: 10px;
					top-left-radius: 0;
					bottom-left-radius: 0;
					bottom-right-radius: 10px;
				}
				padding: 10px 15px;
				border: none;

				.header-item-icon {
					font-size: 24px;
				}
			}

			.form-control {
				height: 40px;
				background: #f3f3f3;
				border: {
					top-left-radius: 10px;
					top-right-radius: 0;
					bottom-left-radius: 10px;
					bottom-right-radius: 0;
				}
				font-size: 14px;
				line-height: 22px;
				color: #c1c1c1;

				&:focus {
					outline: none;
					box-shadow: none;
					border-color: #ced4da;
				}
			}
		}

		.chat-left-header-button {
			background: #f3f3f3;
			border-radius: 10px;
			font-size: 24px;
			color: #333333;
			padding: 8px;
			border: none;
			height: 40px;
		}

		.message-owner-content {
			margin: 0 -5px;
			padding: 0 20px;

			&:hover {
				background-color: #eff4fa;
				cursor: pointer;
			}

			.message-owner-content-top {
				display: flex;
				justify-content: space-between;
				padding: 10px 0;

				.user {
					display: flex;
					align-items: center;
					//   box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.04);

					.user-avatar {
						width: 45px;
						height: 45px;
						background: #c4c4c4;
						border-radius: 50%;
						margin-right: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						overflow: hidden;
					}

					.message-owner-name-content {
						.message-owner-name {
							font-size: 14px;
							line-height: 22px;
							color: #333333;
						}

						.message-owner-position {
							font-size: 12px;
							line-height: 18px;
							color: #c1c1c1;
						}
					}
				}

				.message-written-time {
					.written-time {
						font-size: 14px;
						line-height: 22px;
						color: #333333;
					}

					.written-materials {
						font-size: 12px;
						line-height: 18px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						color: #c1c1c1;
					}

					.unread-message {
						background: #980808;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #fff;
					}
				}
			}

			.message-placeholder {
				padding: 0 0 10px;
				border-bottom: 1px solid #eff2f8;

				.message-owner-text {
					font-size: 12px;
					line-height: 18px;
					color: #c1c1c1;

					.message-owner {
						color: #333333;
					}
				}
			}
		}

		.left-chat-create-chat-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 30px;

			.icon-message-circle {
				margin-right: 10px;
			}
		}
	}

	.chat-placeholder {
		width: 72%;
		height: 100%;
		text-align: center;
		font-weight: 500;
		font-size: 35px;
		margin-top: 250px;
		span {
			font-size: 50px;
		}
	}

	.right-chat-header {
		background-color: #ffffff;
		height: 100vh;

		.user {
			height: 71px;
			padding: 15px 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);

			.user-name {
				display: flex;
				align-items: center;

				.user-avatar {
					width: 45px;
					height: 45px;
					background: #c4c4c4;
					border-radius: 50%;
					margin-right: 20px;
				}

				.message-owner-name-content {
					.message-owner-name {
						font-size: 14px;
						line-height: 22px;
						color: #333333;
					}

					.message-owner-position {
						font-size: 12px;
						line-height: 18px;
						color: #c1c1c1;
					}
				}

				.written-materials {
					width: 45px;
					font-size: 12px;
					line-height: 18px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #c1c1c1;
				}
			}

			.btn-default {
				padding: 14px;
				font-size: 12px;
			}
		}

		.right-chat-list {
			height: calc(100vh - 151px);
			overflow-y: auto;
			padding: 20px 30px;
			background: #eff4fa;
			// box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.04);

			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
				border-radius: 2px;
				background-color: #f5f5f5;
			}

			&::-webkit-scrollbar {
				width: 3px;
				background-color: #f5f5f5;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 2px;
				box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
				background-color: #555;
			}

			.start-message-date {
				width: 120px;
				padding: 3px 10px;
				font-size: 14px;
				line-height: 22px;
				color: #333333;
				background: #ffffff;
				box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
				border-radius: 10px;
				margin: 20px auto;
			}

			.incoming-message,
			.outgoing-message {
				width: 55%;
				background: #ffffff;
				box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
				border-radius: 10px 10px 10px 0;
				font-size: 14px;
				line-height: 22px;
				color: #333333;
				margin: 10px 0;
				padding: 5px 10px;

				.incoming-date {
					font-size: 10px;
					line-height: 14px;
					color: #999999;
					text-align: end;
					// padding-top: 10px;
				}
			}

			.outgoing-message {
				background: var(--primary_color);
				border-radius: 10px 10px 0 10px;
				color: #ffffff;
				margin-left: auto;

				.incoming-date {
					color: #f5f5f5;
				}
			}
			.message-placeholder {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				img {
					width: 374px;
				}
				p {
					font-weight: bold;
					font-size: 22px;
					color: #ff9060;
				}
			}
		}

		.user-typing-list {
			padding: 0px 30px 40px 30px;
			background: #eff4fa;
			display: flex;
			// box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
			position: relative;

			.form-control {
				height: 40px;
				box-shadow: none;
				border-radius: 10px 0 0 10px;
				font-size: 14px;
				line-height: 22px;
				color: #333333;
				padding: 10px 10px 10px 20px;
			}

			.icon-attach {
				position: absolute;
				top: 40px;
				left: 40px;
				font-size: 24px;
				color: #c1c1c1;
			}

			.btn-default {
				border-radius: 0 10px 10px 0;
				height: 40px;
			}
		}
	}
}

.user-account-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	.user-account-img {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 45px;
		height: 45px;
		border-radius: 50%;
		background-color: rgba(239, 244, 250, 0.5);
		border: 1px solid #c1c1c1;
		color: #c1c1c1;
		margin-right: 10px;
		font-size: 24px;
		margin-top: -3px;
		margin-bottom: -3px;
	}

	.user-account-name {
		white-space: nowrap;
	}
}

.custom-date-picker-wrapper {
	@extend %main-date-picker-wrapper;

	.modal-date-picker {
		width: 66px;
	}
	input {
		height: 45px;
		padding-left: 12px;
	}
}

.add-class-wrapper {
	.checked-elements-list {
		max-width: 450px;
		margin-top: 10px;
		display: flex;
		overflow-x: auto;

		&::-webkit-scrollbar-track {
			box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
			-webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
			border-radius: 2px;
			background-color: #f5f5f5;
		}

		&::-webkit-scrollbar {
			height: 5px;
			background-color: #f5f5f5;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 2px;
			background: var(--primary_color);
		}
	}

	.selected-classes-box {
		width: auto;
		height: 28px;
		padding-left: 10px;
		border-radius: 10px;
		background-color: var(--primary_color);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 20px;
		margin-bottom: 10px;

		.selected-classes {
			width: auto;
			height: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #ffffff;
		}

		.btn {
			&:focus {
				outline: none;
				box-shadow: none;
			}
		}
	}

	.stuff-classify {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		color: #00264b;
		margin-bottom: 10px;
	}

	.search-class-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;

		.class-title {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;
			color: #00264b;
		}

		.class-input-group {
			width: 242px;

			.input-group {
				height: 40px;
				flex-wrap: nowrap;
				display: flex;
				align-items: center;
				padding: 0;

				.input-group-text {
					height: 40px;
					border: {
						top-right-radius: 10px;
						bottom-right-radius: 10px;
						top-left-radius: 0;
						bottom-left-radius: 0;
					}
					background: rgba(239, 244, 250, 0.5);
					padding: 10px 8px;
					border: none;

					.header-item-icon {
						font-size: 24px;
					}
				}

				.form-control {
					height: 40px;
					padding-left: 15px;
					background: rgba(239, 244, 250, 0.5);
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
					font-size: 14px;
					line-height: 22px;

					&:focus {
						outline: none;
						box-shadow: none;
						border-color: #ced4da;
					}
				}
			}
		}
	}

	.class-lists-box {
		overflow-y: scroll;
		box-sizing: border-box;
		padding-right: 7px;
		padding-top: 30px;
		height: 480px;

		&::-webkit-scrollbar-track {
			box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
			-webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
			border-radius: 2px;
			background-color: #f5f5f5;
		}

		&::-webkit-scrollbar {
			width: 3px;
			background-color: #f5f5f5;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 2px;
			background: var(--primary_color);
		}

		.class-lists {
			background: rgba(239, 244, 250, 0.5);
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 20px;
			margin-bottom: 10px;
			width: 100%;
			height: 65px;
			border-radius: 10px;

			.class-check-box {
				width: 40px;
				height: 40px;
				position: relative;
				border-radius: 10px;
				background: #ffffff;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				.checked-mark {
					position: absolute;
					top: 5px;
					left: 5px;
					width: 30px;
					height: 30px;
					border-radius: 10px;
					cursor: pointer;

					i {
						position: absolute;
						top: 5px;
						left: 5px;
						font-size: 20px;
						color: #06bf69;
					}
				}

				input {
					opacity: 0;
					cursor: pointer;
				}
			}
		}

		.bg {
			background: #eff4fa;
		}
	}
}

.stuff-add-edit {
	.photo-title {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		color: #00264b;
		margin-bottom: 10px;
	}

	.user-avatar {
		width: 268px;
		height: 268px;
		background: #eff4fa;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 8px;
		position: relative;
		overflow: hidden;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.avatar-delete-button {
			position: absolute;
			right: 0;
			top: 0;
			margin: 2.5rem;
			padding: 0.5rem;

			& > i {
				margin: 0;
			}
		}

		button {
			background: #ff9060;
			border-radius: 10px;
			border: none;
			outline: none;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;
			display: flex;
			align-items: center;
			text-align: center;
			color: #ffffff;
			padding: 10px 15px;
			transition: 0.3s linear;

			i {
				font-size: 24px;
				margin-right: 15px;
			}

			&:hover {
				box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
			}
		}
	}

	.user-avatar-file-label {
		background-color: #ff9060;
		padding: 8px 15px;
		color: #ffffff;
		width: unset;
		border-radius: 10px;

		input {
			opacity: 0;
			position: absolute;
		}
	}

	form {
		.form-text {
			font-size: 10px;
			line-height: 14px;
			color: #c1c1c1;
		}

		.form-group {
			.react-datepicker-wrapper {
				width: 100%;
			}

			margin-bottom: 10px;

			.form-control {
				background: rgba(239, 244, 250, 0.5);
				border-radius: 10px;
				height: 40px;
			}

			.custom-form-control {
				.css-yk16xz-control,
				.css-1pahdxg-control {
					background: rgba(239, 244, 250, 0.5);
					border-radius: 10px;
					border: none;
				}
			}

			.react-datepicker-wrapper {
				width: 100%;

				.react-datepicker__input-container {
					input {
						width: 100%;
						background: rgba(239, 244, 250, 0.5);
						border-radius: 10px;
					}
				}
			}
		}
	}
}

.stuff-add-class {
	//padding: 0 20px;

	.header-title {
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		display: flex;
		align-items: center;
		color: #00264b;

		i {
			font-size: 16px;
			margin-left: 5px;
		}
	}

	.header-title-last {
		display: flex;
		justify-content: flex-start;
		margin-right: 20px;
	}

	.stuff-add-row {
		margin-top: 15px;
		padding-top: 15px;
		border-top: 1px solid #eff2f8;

		.col {
			&:nth-child(1) {
				padding-left: 0;
			}
		}

		.form-control {
			border: 1px solid transparent !important;
			border-radius: 8px;
			transition: 0.2s linear;
			background-color: #fafafa;
			height: 40px;

			&:hover,
			&:active,
			&:focus {
				border: 1px solid var(--primary_color) !important;
				border-radius: 8px;
				box-shadow: none;
			}
		}

		.action {
			display: flex;
			justify-content: flex-end;
		}

		.custom-form-control {
			//border: 1px solid transparent !important;

			border-radius: 8px;
			transition: 0.2s linear;

			&:hover,
			&:active,
			&:focus {
				border: 1px solid var(--primary_color) !important;
				border-radius: 8px;
			}

			.css-yk16xz-control {
				border: none;

				&:hover,
				&:active,
				&:focus {
					cursor: pointer;
					border-color: transparent !important;
				}

				.css-1hb7zxy-IndicatorsContainer {
					span {
						display: none;
					}
				}
			}

			.css-1pahdxg-control {
				border: none;
				outline: none;
				box-shadow: none;

				.css-1hb7zxy-IndicatorsContainer {
					span {
						display: none;
					}
				}
			}
		}
	}
}

.delete-confirm-wrapper {
	z-index: 10;
	border-radius: 11px;
	width: 260px;
}

.delete-confirm-layer {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
	width: 100vw;
	height: 100vh;
}

.form-group {
	input {
		border: 1px solid rgba(0, 0, 0, 0);

		&:hover,
		&:focus,
		&:active {
			border-color: var(--primary_color);
			box-shadow: none !important;
		}
	}
}

.invalid-text {
	color: red;
}
.isvalid-text {
	color: rgba(6, 191, 105, 1);
}

.is-invalid {
	border: 1px solid red;
	border-radius: 10px;
}

.searchable-select-input-changes {
	.css-1uccc91-singleValue {
		margin-top: -5px;
	}
	input {
		margin-top: -5px;
	}
	.css-tlfecz-indicatorContainer {
		margin-top: -5px;
	}
	.css-1okebmr-indicatorSeparator {
		margin-bottom: 12px;
	}
	.css-1gtu0rj-indicatorContainer {
		margin-top: -5px;
	}
}

.day-picker-button {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	width: fit-content;
	height: 40px;
	padding: 15px;
	background-color: #eff4fa;

	outline: none;
	border: none;
	border-radius: 10px;

	i {
		font-size: 16px;
		color: var(--primary_color);
	}

	span {
		color: var(--primary_color);
		font-weight: 500;
		text-transform: capitalize;
	}
}

.year-fiter-container {
	display: grid;
	grid-auto-flow: column;
	gap: 8px;
	align-items: center;
	background-color: #eef4fa;
	border-radius: 8px;
	height: 40px;
	padding: 0 16px;
}

.filter-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	background-color: transparent;
	border: none;
	outline: none;
}

.filter-chevron {
	font-weight: bold;
}

.searchible-input {
	border: none;
}
.searchible-input:focus {
	outline: none;
}

.events-calendar-wrapper {
	padding-top: 16px;
	table {
		th,
		td,
		a {
			color: #232a3e;
		}
	}
}
.upload_button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #fff !important;
	border: none !important;

	&.btn {
		box-shadow: none !important;
	}
	&.btn:focus {
		outline: none;
		box-shadow: none;
	}

	svg {
		margin-right: 10px;
	}
	span {
		font-size: 14px;
		line-height: 22px;
		text-decoration-line: underline;
		color: #00264b;
	}

	input {
		display: none;
		visibility: hidden;
	}
}

.phone-input {
	padding-left: 50px;
}

.rollback-select {
	border: 1px solid transparent !important;

	div {
		color: #000000 !important;
	}

	.css-yk16xz-control,
	.css-1pahdxg-control {
		background-color: #eff4fa;
	}

	.css-tlfecz-indicatorContainer,
	.css-1gtu0rj-indicatorContainer {
		position: relative;
		left: 8px;
	}
}

.validate-error {
	border: 1px solid #ff4d4f !important;
}

.replacement_date_picker {
	width: 100% !important;
	background-color: #eff4fa !important;
}

.replacement_picker_wr {
	.form-group {
		display: flex;
		align-items: center;
		justify-content: space-between;

		svg {
			margin-right: 10px;
		}
	}
}

.child-date {
	display: flex;
	flex-shrink: 0;
	font-weight: 500;
	font-size: 13.5px;
	position: relative;
	padding: 12px 14px 12px 10px;
	border-left: 1px solid #eee;
	color: #e87525;
}

.input-checkbox {
	cursor: pointer;
	accentcolor: green;
	color: white;
}

.overflowY-auto {
	overflow-y: auto !important;
}

.staff-room__input-field::placeholder {
	color: #000000 !important;
}

.stuff-lessons-list {
	height: 60px;

	&:nth-child(even) {
		background-color: #eff4fa80;
	}
}

.staff-room__control {
	padding: 0 10px !important;
}

.control-upload-svg {
	rect {
		fill: hsl(0, 0%, 80%);
	}
}

.class-register-upload-btn {
	flex-direction: row-reverse;
	gap: 10px;
	border-radius: 10px !important;
	background-color: #06bf69 !important;
	padding: 9px 15px !important;

	span {
		text-decoration: unset !important;
		color: #ffffff !important;
	}

	svg {
		color: #ffffff;
	}
}
