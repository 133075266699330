.journal-table {
	.custom-table-wrapper {
		.custom-table {
			.table {
				thead {
					tr {
						th {
							&:first-child {
								padding-top: 10px;
								border-left: none;
							}

							padding-top: 0;
							border-left: 1px solid #eff4fa;
							position: relative;

							.journal-table-header-title {
								height: 198px;
								font-weight: 500;
								font-size: 14px;
								line-height: 22px;
								color: #3f3d56;
								display: flex;
								flex-direction: column;
								justify-content: space-between;

								.journal-table-header-task-title {
									font-weight: normal;
									font-size: 13px;
									line-height: 22px;
									color: #3f3d56;
								}

								p {
									font-weight: normal;
									font-size: 12px;
									line-height: 22px;
									color: #3f3d56;
									display: flex;
									align-items: center;

									&:nth-child(2) div {
										background-color: #ffa495;
									}
									&:nth-child(3) div {
										background-color: var(--primary_color);
									}
									&:nth-child(4) div {
										background-color: #7ec42f;
									}

									div {
										display: inline-block;
										width: 10px;
										height: 10px;
										border-radius: 50%;
										margin-right: 10px;
										background-color: #ff4949;
									}
								}
							}

							.journal-table-header-rotate-content {
								height: 208px;
								display: flex;
								flex-direction: column;
								justify-content: space-between;
								align-items: center;

								.journal-table-header-date {
									font-weight: 500;
									font-size: 10px;
									line-height: 22px;
									color: #3f3d56;
								}

								.journal-table-header-rotate-title {
									font-weight: normal;
									font-size: 14px;
									line-height: 22px;
									color: #3f3d56;
									transform: rotate(-90deg);
									width: 135px;
									position: absolute;
									top: 90px;
									left: -31px;
								}
							}
						}
					}
				}

				tbody {
					tr {
						td {
							text-align: center;
							font-size: 13px;
							line-height: 22px;
							position: relative;
						}
					}
				}
			}
		}
	}
}

.journal-point-5 {
	background: #f1f8ea;
	color: #7ec42f;
}

.journal-point-4 {
	background: #fef8e8;
	color: var(--primary_color);
}

.journal-point-3 {
	background: #ffedea;
	color: #ff4949;
}

.journal-point-2 {
	background: #ffd7d0;
	color: #ff4949;
}

.journal-point {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
