.plans_list__upload_section {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 4px;
	margin-top: 4px;
	padding: 0 10px;

	.upload_button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #fff !important;
		border: none !important;

		&.btn {
			box-shadow: none !important;
		}
		&.btn:focus {
			outline: none;
			box-shadow: none;
		}

		svg {
			margin-right: 10px;
		}
		span {
			font-size: 14px;
			line-height: 22px;
			text-decoration-line: underline;
			color: #00264b;
		}

		input {
			display: none;
			visibility: hidden;
		}
	}
}

.plan-table_wrap {
	//display: flex;
	border: 1px solid #ffffff;
	border-radius: 10px;
	//height: calc(100vh - 254px);
	height: 100%;
	overflow-x: auto;
	position: relative;
	&::-webkit-scrollbar {
		width: 4px;
		height: 6px;
		background: #eff4fa;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #efa06a;
		border-radius: 10px;
		width: 2px;
	}
}
.table_plan {
	flex: 1;
	thead {
		background-color: #e87525 !important;
		color: #ffffff;
		position: sticky;
		top: 0.5px;
		z-index: 50;
	}
	tbody {
		//&:nth-child(even) {
		//	background: #e6e7e9;
		//}
		tr {
			background-color: #fff;

			th {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: 0.01em;
				color: #05111a;
				padding: 14px;
				border: 1px solid #fff;
			}

			&:first-child {
				// background-color: var(--primary_color) !important;

				th {
					color: #ffffff;
				}
			}
		}
	}
}

.table_freezy {
	th {
		z-index: 40;
		opacity: 1;
		height: 75px !important;
		text-align: center;
	}

	td:nth-child(1) {
		position: sticky;
		left: 0px;
		z-index: 2;
		min-width: 230px !important;
		width: 230px !important;
		//display: inline-block;

		opacity: 1;
		background: #fff;
	}
	th:nth-child(1) {
		position: sticky;
		left: 0px;
		min-width: 230px !important;
		width: 230px !important;
		//display: inline-block;

		z-index: 40;
		opacity: 1;
		background-color: #e87525 !important;
	}
	td:nth-child(2) {
		position: sticky;
		left: 230px;
		z-index: 2;
		//opacity: 1;
		background: #fff;
	}
	th:nth-child(2) {
		position: sticky;
		left: 230px;
		z-index: 40;
		//opacity: 1;
		background-color: #e87525 !important;
	}
}

.table-small-width {
	td {
		padding: 0.45rem;
	}
}
