@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon.eot?dz588x');
	src: url('../fonts/icomoon.eot?dz588x#iefix') format('embedded-opentype'), url('../fonts/icomoon.ttf?dz588x') format('truetype'),
		url('../fonts/icomoon.woff?dz588x') format('woff'), url('../fonts/icomoon.svg?dz588x#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-add-lesson-calendar:before {
	content: '\e900';
}
.icon-alert-circle:before {
	content: '\e901';
}
.icon-announcement:before {
	content: '\e902';
}
.icon-arrow-down:before {
	content: '\e903';
}
.icon-arrow-right:before {
	content: '\ea3c';
}
.icon-arrow-left:before {
	content: '\ea38';
}
.icon-arrow-up:before {
	content: '\e904';
}
.icon-attach:before {
	content: '\e905';
}
.icon-bar-chart:before {
	content: '\e906';
}
.icon-book-solid:before {
	content: '\e907';
}
.icon-briefcase-medical:before {
	content: '\e908';
}
.icon-bxs-calendar:before {
	content: '\e909';
}
.icon-bx-smile:before {
	content: '\e90a';
}
.icon-bx-smile-green:before {
	content: '\e90b';
}
.icon-calendar:before {
	content: '\e90c';
}
.icon-calendar-tick-solid:before {
	content: '\e90d';
}
.icon-card:before {
	content: '\e90e';
}
.icon-card-play:before {
	content: '\e90f';
}
.icon-catalogue:before {
	content: '\e910';
}
.icon-check-circle:before {
	content: '\e911';
}
.icon-chevron-down1:before {
	content: '\e912';
}
.icon-clock-circle-outlined:before {
	content: '\e913';
}
.icon-close:before {
	content: '\e914';
}
.icon-cloud-download:before {
	content: '\e915';
}
.icon-cloud-upload:before {
	content: '\e916';
}
.icon-component:before {
	content: '\e917';
}
.icon-conference-room:before {
	content: '\e918';
}
.icon-delete:before {
	content: '\e919';
}
.icon-drag:before {
	content: '\e91a';
}
.icon-edit:before {
	content: '\e91b';
}
.icon-edit-user:before {
	content: '\e91c';
}
.icon-help-circle:before {
	content: '\e91d';
}
.icon-home:before {
	content: '\e91e';
}
.icon-lesson-attach:before {
	content: '\e91f';
}
.icon-lesson-clock:before {
	content: '\e920';
}
.icon-lesson-home-person:before {
	content: '\e921';
}
.icon-lesson-user-group:before {
	content: '\e922';
}
.icon-list-solid:before {
	content: '\e923';
}
.icon-map-marker-line:before {
	content: '\e924';
}
.icon-Mask:before {
	content: '\e925';
}
.icon-message:before {
	content: '\e926';
}
.icon-message-circle:before {
	content: '\e927';
}
.icon-message-square:before {
	content: '\e928';
}
.icon-options:before {
	content: '\e929';
}
.icon-plus:before {
	content: '\e92a';
}
.icon-role-binding:before {
	content: '\e92b';
}
.icon-save-mark:before {
	content: '\e92c';
}
.icon-search:before {
	content: '\e92d';
}
.icon-sign-eye:before {
	content: '\e92e';
}
.icon-sign-lock:before {
	content: '\e92f';
}
.icon-sign-up-email:before {
	content: '\e930';
}
.icon-sign-user:before {
	content: '\e931';
}
.icon-smartphone:before {
	content: '\e932';
}
.icon-students:before {
	content: '\e933';
}
.icon-teachers:before {
	content: '\e934';
}
.icon-university:before {
	content: '\e935';
}
.icon-warning:before {
	content: '\e93b';
}
.icon-chevrons-right:before {
	content: '\e936';
}
.icon-chevrons-left:before {
	content: '\e937';
}
.icon-chevron-right:before {
	content: '\e938';
}
.icon-chevron-left:before {
	content: '\e939';
}
.icon-chevron-down:before {
	content: '\e93a';
	font-size: 24px;
	color: #333333;
	font-weight: bold;
}
