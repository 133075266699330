.search-container {
	width: 100%;
	position: relative;
	margin-bottom: 16px;
}
.search-input-label {
	margin-bottom: 8px;
}

.search-input-container {
	position: relative;
	width: 100%;
	margin: 0;
	i {
		position: absolute;
		z-index: 1;
		font-size: 24px;
		left: 14px;
		top: 50%;
		transform: translateY(-50%);
	}

	input {
		width: 100%;
		height: 40px;
		background-color: #f3f3f3;
		border-radius: 10px;
		padding: 0 54px 0 54px;
		border: none;
		outline: none;
		:focus {
			outline: none;
			border: none;
		}
	}

	.search-input-clear-button {
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		background-color: #00264b;
		border-radius: 15px;
		outline: none;
		border: none;
		svg {
			width: 18px;
			height: 18px;
			path {
				fill: #fff;
			}
		}
	}
}

.search-result-container {
	z-index: 100;
	position: absolute;
	width: 100%;
	top: 80px;
	background-color: #fff;
	border: 1px solid #999;
	padding: 4px 0;
	border-radius: 10px;

	.search-result-item {
		display: grid;
		grid-auto-flow: column;
		align-items: center;
		grid-template-columns: auto 1fr;
		height: 40px;
		gap: 15px;
		transition: 0.2s;
		padding: 0 15px;
		margin: 0 !important;

		button {
			height: 30px;
			border: none;
			border-radius: 5px;
			background-color: var(--success);
			padding: 0 8px;

			font-size: 12px;
			color: #fff;
		}
		button.button-exist {
			background-color: var(--gray);
		}
	}
	.search-result-item:hover {
		background-color: rgba(239, 244, 250, 0.5);
	}
}
