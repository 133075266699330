%main-corner {
	background: #ffffff;
	border-radius: 10px;
	position: relative;
	z-index: 10;
}

.custom-table {
	.table {
		margin-bottom: 0;
		border: none;
		background: #fafafa;

		thead {
			.table-head-bottom {
				background-color: #ffffff;

				th {
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 22px;
					color: #c1c1c1;
					&:last-child {
						text-align: center;
					}
				}
			}
		}

		tbody {
			tr {
				border-bottom: 1px solid #ffffff;

				&:last-child {
					border-bottom: none;
				}

				td {
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 22px;
					color: #333333;
				}
			}
		}

		th,
		td {
			border: none;
			padding: 21px 20px 22px;
		}
	}
}

.custom-table::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.custom-table::-webkit-scrollbar-track {
	background: var(--lightestgrey);
}

.custom-table::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.2);
	border-radius: 5px;
}

.custom-table::-webkit-scrollbar-thumb:hover {
	background: rgba(9, 8, 8, 0.2);
}

.custom-table-wrapper {
	@extend %main-corner;

	.custom-table {
		border: 1px solid #ffffff;
		border-radius: 10px;
		//height: calc(100vh - 154px);
		overflow-y: auto;
		position: relative;

		.table {
			margin-bottom: 0;
			border: none;
			background: #ffffff;

			thead {
				position: sticky;
				left: 0;
				top: 0;
				z-index: 1;
				.table-head-bottom {
					th {
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 22px;
						color: #c1c1c1;
						border-bottom: 1px solid #eff2f8;

						&:last-child {
							text-align: end;
						}
					}
				}
			}

			tbody {
				.custom-table-row {
					background-color: #ffffff;
					border-bottom: 2px solid #ffffff;
					transition: all 0.3s ease;
					&:nth-child(even) {
						background: rgba(239, 244, 250, 0.5);
					}
					&:hover {
						cursor: pointer;
						background-color: #e5e5e5;
					}

					td {
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 22px;
						color: #333333;
						vertical-align: inherit;

						&:last-child {
							position: relative;

							.btn-light-default {
								margin-left: 10px;

								&:first-child {
									margin-left: 0;
								}
							}
						}

						.switch {
							display: flex;
							align-items: center;
							margin-left: 15px;
						}
					}
				}
			}

			th,
			td {
				border: none;
				padding: 10px 20px 10px !important;
			}
		}
	}
}

.table-actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 100%;
}
.table-item-avatar {
	min-width: 45px;
	height: 45px;
	border-radius: 50%;
	background-color: #c1c1c1;
}

.schedule-table-wrapper {
	padding: 20px 23px 150px;
	@extend %main-corner;
	border-top-left-radius: 0;

	.schedule-date-box {
		display: flex;
		justify-content: center;

		.schedule-box {
			width: 229px;
			height: 40px;
			margin-bottom: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: rgba(239, 244, 250, 0.5);
			border-radius: 10px;

			i {
				font-size: 24px;
				color: #3f3d56;
				cursor: pointer;
			}

			.schedule-date {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				color: #3f3d56;
			}
		}
	}

	.schedule-table {
		border-radius: 10px 10px 0 0;
		border: 1px solid #dddddd;
		overflow: hidden;

		.table {
			background: #ffffff;
			border-radius: 10px 10px 0 0;
			border: none;
			margin-bottom: 0;

			thead {
				tr {
					th {
						border-top: none;
						border-bottom: none;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 17px;
						text-align: center;
						letter-spacing: -0.3px;
						color: #ffffff;
						background-color: #c1c1c1;
						padding: 17px 32px;

						i {
							font-size: 24px;
						}

						&:first-child {
							border-left: none;
							background-color: transparent;
							color: #000000;
						}

						&:last-child {
							border-right: none;
						}
					}
				}
			}

			tbody {
				.table-body-clock {
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 19px;
					text-align: center;
					letter-spacing: -0.3px;
					color: #000000;
				}

				td {
					padding: 10px 10px 4px 10px;

					&:first-child {
						border-left: none;
					}

					&:last-child {
						border-right: none;
					}

					.science {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 12px;
						color: #000000;
					}

					.conference {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: 4px;

						.conference-user {
							width: 70%;
							font-style: normal;
							font-weight: normal;
							font-size: 12px;
							line-height: 12px;
							color: #000000;
						}

						.conference-door {
							width: 30%;
							height: 17px;
							display: flex;
							align-items: center;
							justify-content: space-between;

							.icon-conference-room {
								font-size: 18px;
							}

							.conference-count {
								font-style: normal;
								font-weight: normal;
								font-size: 12px;
								line-height: 22px;
								color: #000000;
								margin-left: 7px;
							}
						}
					}
				}

				.last-piece {
					border-bottom: none;
				}
			}
		}
	}
}

.table-role {
	padding: 33px 20px 0;

	.table {
		padding: 0 20px;
		margin-bottom: 0;

		thead {
			tr {
				th {
					border-top: none;
					border-bottom: 1px solid #eff2f8;
					padding: 15px 0;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 22px;
					color: #c1c1c1;

					&:last-child {
						text-align: right;
						padding-right: 26px;
					}

					.icon-bar-chart {
						font-size: 16px;
						margin-left: 10px;
					}
				}
			}
		}

		tbody {
			tr {
				td {
					border: none;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 22px;
					color: #333333;
					padding: 21px 0;
					vertical-align: middle;

					.btn {
						margin-left: 10px;

						&:first-child {
							margin-left: 0;
						}
					}

					&:last-child {
						text-align: right;
					}
				}
			}
		}
	}
}
